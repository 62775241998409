import React from 'react';
import PageHeader from "./Header"
import Form from "./Body"

function App() {
  return (
    <div className="App">
      <PageHeader />
      <Form />
    </div>
  );
}

export default App;
