import React from 'react';
import "../stylesheets/App.css"

function PageHeader() {
  return (
    <>
      <header>
        <img className='header--img' src={'./img/cinta.png'} />
        <h2 className='header--title'>Monkey Generator</h2>
        <h4 className='header--project'> React Project </h4>
      </header>
    </>
  );
}

export default PageHeader;
