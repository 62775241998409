import React from 'react';
import "../stylesheets/App.css"


function App() {
  var [monkeyImg, setMonkeyImg] = React.useState("")

  function between(min: number, max: number) {
    return Math.floor(
      Math.random() * (max - min + 1) + min
    )
  }

  const [monke, setMonke] = React.useState({
    topText: "",
    bottomText: "",
    randomImage: ""
  })

  function getMonkey() {
    var url = `https://www.placemonkeys.com/500/350?random=${between(0, 20)}`
    setMonke(previousMonke => ({
      ...previousMonke,
      randomImage: url
    }))
    // setMonkeyImg(url)
  }

  function handleChange(event: any) {
    setMonke(prevMonke => {
      return {
        ...prevMonke,
        [event.target.name]: event.target.value
      }
    })
  }

  function handleSubmit(event: any) {
    event.preventDefault()
    console.log(monke);


  }

  return (
    <main>
      <form onSubmit={handleSubmit}>
        <input type='text' className='form--input' placeholder='Top text' name="topText" onChange={handleChange} value={monke.topText} />
        <input type='text' className='form--input' placeholder='Bottom Text' name="bottomText" onChange={handleChange} value={monke.bottomText} />
        <button className='form--button' onClick={getMonkey}> Make monke </button>
      </form>
      <div className='monke'>
        <img src={monke.randomImage} className="monke--image" />
        <h2 className='monke--text top' >{monke.topText}</h2>
        <br />
        <h2 className='monke--text bottom'>{monke.bottomText}</h2>
      </div>
    </main>
  );
}

export default App;
